/*
 |-----------------------------------------
 | Table of Contents
 |
 |
 | 1.0 Fonts
 |
 | 2.0 Element Rules
 | 	2.1 Custom Select
 |	2.2 Icon rules
 |
 | 3.0 General Rules
 | 	3.1 Colors
 |  3.2 Panels
 | 	3.3 Footer
 | 	3.4 Typeahead
 | 	3.5 Radio select and hide
 | 	3.6 Checkbox enable
 | 	3.7 Info box triggered through hovering
 | 	3.8 Arrows
 |
 | 4.0 Navigation
 | 	4.1 Booking Steps
 |
 | 5.0 Layouts
 |
 | 7.0 Submit Pages
 |
 |-----------------------------------------
 */

@import "../_variables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

/*
 |-----------------------------------------
 | 2.0 Element Rules
 |-----------------------------------------
 */

html,
body {
	height: 100%; }

body {
	letter-spacing: 0.5px;
	position: relative;
	overflow-x: hidden;
	overflow-y: visible; }

.bodystrong {

	&::after {
		//background: url('/resources/background_corrected_centered.jpg');
		background-size: cover;
		background-repeat: no-repeat;
		content: '';
		opacity: 1.0;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		position: fixed;
		z-index: -1; } }

.bodydim {

	&::after {
		//background: url('/resources/background_corrected_centered.jpg');
		background-size: cover;
		background-repeat: no-repeat;
		//background-color: black;
		content: '';
		//opacity: 0.1;
		//opacity: 0.4;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		position: fixed;
		z-index: -1; } }

/*
 |-----------------------------------------
 |		 2.1 Custom Select
 |-----------------------------------------
 */

select.custom {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20fill%3D%22%23555555%22%20%0A%09%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%22-261%20145.2%2024%2024%22%20style%3D%22enable-background%3Anew%20-261%20145.2%2024%2024%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cpath%20d%3D%22M-245.3%2C156.1l-3.6-6.5l-3.7%2C6.5%20M-252.7%2C159l3.7%2C6.5l3.6-6.5%22%2F%3E%0A%3C%2Fsvg%3E");
  padding-right: 25px;
  background-repeat: no-repeat;
  background-position: right center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }


select.custom::-ms-expand {
  display: none; }


/*
 |-----------------------------------------
 |		 2.2 Icon rules
 |-----------------------------------------
 */

.login-icon {
	height: 35px;
	display: inline-block;
	vertical-align: middle;
	margin: 0 2px 8px; }



/*
 |-----------------------------------------
 | 3.0 General Rules
 |-----------------------------------------
 */

.inline {
	display: inline-block; }

h1.inline,
h2.inline,
h3.inline,
h4.inline,
h5.inline,
h6.inline {
	margin-right: .25em; }

.monospace {
	font-family: 'Courier New', Monaco, monospace; }

.subtitle {
	margin: 15px 0; }

span.powerOf {
	position: relative;
	bottom: 1ex;
	font-size: 80%; }

.text-right {
	text-align: right; }

.text-danger {
	color: #D3202E; }

.bg-extrem-danger {
	background-color: $red;
	color: #fff; }

.form-inline {
	& > .form-group {
		margin-bottom: 20px; }

	& * .form-group {
		margin-bottom: 5px; }

	&.smallInput .form-group input,
	&.smallInput .form-group select {
		padding: 3px;
		margin-right: 7px;
		padding-right: 14px;
		height: 30px; } }

.form-group {
	margin-bottom: 5px;

	label,
	select,
	input {
		border-radius: 0;
		border-width: 1px;
		border-color: #aaa;
		display: block; }

	input[type="checkbox"],
	input[type="checkbox"] ~ label {
		height: auto;
		display: inline-block;
		width: auto;
		margin-top: 0; } }

.form-control {
	height: 38px;
	font-size: 18px; }

.form-spacer-sm {
	height: 10px; }

.form-spacer-md {
	height: 20px; }

.form-spacer-lg {
	height: 40px; }

.form-spacer-xl {
	height: 60px; }

span.bordered {
	border: 1px solid #ddd;
	padding: 5px 7px; }

table {
	.bordered tr td,
	.bordered tr th {
		border: 1px solid #999; }

	.dotted tr td,
	.dotted tr th {
		border: 1px dotted #ddd;
		color: #333;

	 .highlight {}
		background-color: $highlight; }

	.lightPadding tr td,
	.lightPadding tr th {
		padding: 5px; } }

.glyphicon-remove {
	color: $red;
	cursor: pointer; }

.centerChildren {
	text-align: center; }

.button-group {
	margin-top: 10px; }

/*
 |-----------------------------------------
 | 		3.1 Colors
 |-----------------------------------------
 */

.blue {
	background-color: $blue;

	label,
	.brightFont {
		color: #eee; }

	input,
	textarea,
	select {
		color: #333; }

	::-webkit-input-placeholder {
	   color: #555; }

	-moz-placeholder: /* Firefox 18- */ {
	   color: #555; }

	::-moz-placeholder  /* Firefox 19+ */ {
	   color: #555; }

	:-ms-input-placeholder {
	   color: #555; } }


.lightBlue {
	background-color: #3ddff9; }


.red {
	background-color: #c1474d;
	color: #eee; }



/*
 |-----------------------------------------
 | 		3.2 Panels
 |-----------------------------------------
 */

.panel-default {
	border-radius: 0;
	//border: none;
	background-color: rgba(255, 255, 255, 1.0);
	//padding: 20px;
	box-shadow: 6px 5px 8px -2px rgba(0,0,0,0.5), 2px 2px 3px rgba(0,0,0,0.2);
	margin-bottom: 0;

	.panel-heading {
		background-color: $gray-lighter;
		//border: none;
		//padding: 15px 25px 0px;

		h2 {
			margin: 0;
			text-align: center;
			font-weight: 300; } }

	.panel-body {
		padding: 10px 25px 20px;
		background-color: #fff; }

	.panel-bottom-0 /* Visual optimization on the login page for the "Forgot Password" link etc. */ {
		padding-bottom: 0; } }



/*
 |-----------------------------------------
 | 		3.3 Footer
 |-----------------------------------------
 */

.footer {
	font-size: 18px;
	position: relative;
	margin-top: 0px;
	height: 50px;
	clear: both;
	padding-top: 15px;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 10px;
	background-color: rgba(255,255,255,1.0);
	//box-shadow: 0px 0px 10px rgba(255,255,255,1.0);
	//box-shadow: 6px 5px 8px -2px rgba(0,0,0,0.5), 2px 2px 3px rgba(0,0,0,0.2);
	border-top: 1px solid;
	border-color: $gray-lighter; }

.wrap {
	min-height: 100%; }
/*
 |-----------------------------------------
 | 		3.4 Typeahead
 |-----------------------------------------
 */

.typeahead,
.tt-query,
.tt-hint {
	//width: 396px;
	height: 38px;
	padding: 6px 12px;
	font-size: 16px;
	line-height: 30px;
	//border: 2px solid #ccc;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	border-radius: 0px;
	outline: none; }

.typeahead {
	background-color: #fff; }

.typeahead:focus {
	border: 2px solid #0097cf;

 .tt-query {}
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.tt-hint {
	color: #999; }

.tt-menu {
	width: 422px;
	margin: 12px 0;
	padding: 8px 0;
	background-color: #fff;
	border: 1px solid #ccc;
	border: 1px solid rgba(0, 0, 0, 0.2);
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	border-radius: 0px;
	-webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);
	-moz-box-shadow: 0 5px 10px rgba(0,0,0,.2);
	box-shadow: 0 5px 10px rgba(0,0,0,.2); }

.tt-suggestion {
	padding: 3px 20px;
	font-size: 18px;
	line-height: 24px; }

.tt-suggestion:hover {
	cursor: pointer;
	color: #fff;
	background-color: #0097cf; }

.tt-suggestion.tt-cursor {
	color: #fff;
	background-color: #0097cf; }

.tt-suggestion p {
	margin: 0; }

.gist {
	font-size: 14px; }


/*
 |-----------------------------------------
 | 		3.5 Radio select and hide
 |-----------------------------------------
 */

.radioLabel {
	margin-left: 10px;
	cursor: pointer;

	&.tabs {
		border: 1px solid #444;
		padding: 10px;
		margin-left: 1px;
		margin-bottom: 0;
		margin-bottom: -1px;
		background-color: #fff; }

	&.tabs:first-of-type {
		margin-left: 10px; } }

#option_1 {
	display: inline; }

#option_2,
#option_3 {
	display: none; }

// #option_2_select,
// #option_3_select
// 	&[type="radio"]:checked ~ #option_1,
// 	&[type="radio"]:checked ~ div:not(.option_break) #option_1
// 		display: none;

// #option_2_select[type="radio"]:checked ~ #option_2,
// #option_2_select[type="radio"]:checked ~ div:not(.option_break) #option_2,
// #option_3_select[type="radio"]:checked ~ #option_3,
// #option_3_select[type="radio"]:checked ~ div:not(.option_break) #option_3
// 	display: inline


input[data-show="option_2"],
input[data-show="option_3"] {
	&:checked {
		& ~ #option_1,
		& ~ div:not(.option_break) #option_1 {
			display: none; } } }

input[data-show="option_2"]:checked ~ #option_2,
input[data-show="option_2"]:checked ~ div:not(.option_break) #option_2,
input[data-show="option_3"]:checked ~ #option_3,
input[data-show="option_3"]:checked ~ div:not(.option_break) #option_3 {
	display: inline; }

input[data-show="option_2"]:checked ~ #option_2.block,
input[data-show="option_2"]:checked ~ div:not(.option_break) #option_2.block,
input[data-show="option_3"]:checked ~ #option_3.block,
input[data-show="option_3"]:checked ~ div:not(.option_break) #option_3.block {
	display: block; }

#option_1_select[type=radio]:checked ~ .radioLabel[for="option_1_select"],
#option_2_select[type=radio]:checked ~ .radioLabel[for="option_2_select"],
#option_3_select[type=radio]:checked ~ .radioLabel[for="option_3_select"] {
	border-bottom: 1px solid #fff; }

.tabContent {
	padding-top: 10px;
	border-top: 1px solid #444; }


/*
 |-----------------------------------------
 | 		3.6 Checkbox enable
 |-----------------------------------------
 */

.toEnable {
	display: none; }

#check_enable[type=checkbox]:checked ~ .toEnable {
	display: block; }



/*
 |-----------------------------------------
 | 		3.7 Info box triggered through hovering
 |-----------------------------------------
 */

.hoverButton {
	position: relative;
	cursor: pointer;
	padding-right: 6px;

	.hoverBox {
		position: absolute;
		width: 250px;
		font-family: $defaultFont;
		font-size: 14px;
		line-height: 17px;
		right: -20px;
		top: 100%;
		background-color: #fefefe;
		z-index: 100;
		margin-top: 2px;
		padding: 12px 14px;
		border: 2px solid $mediumBlue;
		display: none;
		opacity: 0; }

	.hoverBox.right {
		left: -20px; } }

.circle {
	border: 2px solid #222;
	border-radius: 50%;
	margin-top: 0;
	height: 25px;
	width: 25px;
	padding: 0;
	font-size: 16px;
	text-align: center;

	i {
		margin-top: 3px;
		vertical-align: super;
		font-size: 16px;
		text-align: left; } }

.hoverButton:hover .hoverBox, {
	display: block;
	opacity: 1;
	animation: display-none-transition 0.5s both 0.5s; }

@keyframes display-none-transition {
	0% {
		opacity: 0; } }



/*
 |-----------------------------------------
 | 		3.8 Arrows
 |-----------------------------------------
 */

.arrow-down {
	width: 0;
	height: 0;
	border-top: 12px solid $blue;
	border-left: 12px solid transparent;
	border-right: 12px solid transparent;
	border-bottom: 12px solid transparent; }



/*
 |-----------------------------------------
 | 5.0 Layout
 |-----------------------------------------
 */

.frame {
	width: 1170px;
	margin: 0 auto; }



/*
 |-----------------------------------------
 | 7.0 Submit Pages
 |-----------------------------------------
 */

$tabContentBorder: 1px solid #ddd;

.form.submit {
	border: 3px dashed #eee; }

.tab-content {
	margin: 0 0 20px;
	height: auto;
	overflow: hidden;
	transition: max-height 0.25s ease-out;
	border-top: $tabContentBorder;
	border-bottom: $tabContentBorder; }

input.trigger[type=checkbox]:not(:checked) ~ .tab-content {
	max-height: 0px; }

.tab-content-wrapper {
	margin: 10px 0; }
