
@import "../_variables";



/*
 |-----------------------------------------
 | 4.0 Navigations
 |-----------------------------------------
 */


/*
 |-----------------------------------------
 | 6.0 Frontpage
 |-----------------------------------------
 */

body.frontpage {
	display: flex;
	flex-flow: column;

	&::after {
		content: '';
		background: url('/resources/background_corrected_centered.jpg') no-repeat;
		background-size: cover;
		opacity: 0.85;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1; }

	.navbar {
		flex: 0 1 auto; }

	.container-fluid {
		max-width: 1350px;
		height: 100%;
		flex: 1 1 auto;

		& > .row {
			position: relative;
			top: 50%;
			min-height: 350px;
			transform: translateY(-50%); } }

	&.login {
		.container-fluid {
			width: 420px;

			& > .row {
				top: 50%;
				transform: translateY(-50%); } } } }



/*
 |-----------------------------------------
 | 			6.1 Searchform
 |-----------------------------------------
 */

form.oneLineForm {
	font-weight: 300;
	padding: 20px;
	display: inline-block;
	border-radius: 0;
	background-color: rgba(255,255,255,0.7);
	box-shadow: 6px 5px 8px -2px rgba(0,0,0,0.5), 2px 2px 3px rgba(0,0,0,0.2);

	#details {
		width: 100%;

		span.bg-extrem-danger {
			margin-left: 0%;
			padding: 4px 2px 3px 3px; }

		#dangerous_goods {
			height: 18px;
			width: 18px;
			border-radius: 0px; }

		label {
			margin-bottom: 2px; } }

	label {
		font-weight: 300; }

	input[type="radio"],
	input[type="checkbox"] {
		& + label {
			font-size: 12px; } }

	.datepicker {
		border-radius: 0;
		z-index: 999999 !important; }

	#searchLine {
		margin: 0;
		border-radius: 0;
		position: relative;

		.unitSelect {
			position: relative;
			right: 131px;
			top: -26px; }

		.inputAddOn,
		input:not([type="radio"]),
		span.twitter-typeahead {
			float: left;
			border-radius: 0; }

		//span:first-of-type.twitter-typeahead input
		///border-left: 4px solid $mediumBlue;

		> input:last-of-type:not([type="radio"]) {
			border-right: 4px solid $mediumBlue; }

		input:not([type="radio"]) {
			border-left: 2px; }

		.inputAddOn,
		input:not([type="radio"]) {
			vertical-align: top;
			height: 47px;
			font-size: 16px;
			font-weight: 300;
			padding: 11px 4px 6px;
			border-top: 4px;
			border-right: 0;
			border-bottom: 4px;
			border-color: $mediumBlue;
			border-style: solid;
			border-radius: 0; }

		.inputPlus {
			float: left;
			position: relative;

			input,
			span.twitter-typeahead {
				width: 100%; }

			input {
				padding-right: 30px; }

			.inputAddOn {
				position: absolute;
				right: 0;
				border-left: 0;
				padding: 7px 4px 9px;
				width: 33px; } }

		input[disabled],
		input[disabled] ~ .inputAddOn {
			background-color: #eeeeef; }

		.port, {
			width: 40%;
			border-left: 2px solid $mediumBlue;
			border-right: 4px solid $mediumBlue; }

		#date {
			width: 18%;
			border-left: 4px solid $mediumBlue; }

		.laddingValues {
			width: 18%; }

		#packageCount {
			width: 18%; }

		#submit {
			position: relative;
			height: 47px;
			//top: -28px;
			width: 18%; } } }



/*
 |-----------------------------------------
 | 			6.2 Search Result
 |-----------------------------------------
 */

#searchResults {
	> div {
		height: 330px;
		background-color: #fff;
		background-clip: content-box; }

	.wrapper {
		position: absolute;
		bottom: 22px; }

	.route {
		text-align: center;
		top: 75px;
		right: 10px;
		left: 10px;

		.clampInfo {
			display: inline-block;
			font-size: 23px; }

		.clamp {
			margin: -8px auto 0;
			width: 74.5%;
			height: 21px;
			background-color: #aaa;
			background-clip: content-box;
			padding: 10px 0;
			border-left: 1px;
			border-right: 1px;
			border-top: 0;
			border-bottom: 0;
			border-style: solid;
			border-color: #aaa; }

		#steps {
			width: 98%;
			position: relative;
			vertical-align: top;

			* {
				vertical-align: top; }

			.connection {
				height: 5px;
				background-color: $mediumBlue;
				position: absolute;
				transform: translateX(-50%);
				left: 50%;
				top: 18px;
				width: 75%;
				margin: 0 auto; }

			.step {
				height: 61px;
				width: 20%;
				.text {
					background-color: #fff;
					border: 14px solid $mediumBlue;
					border-radius: 50%;
					height: 40px;
					width: 40px; }
				.info {
					display: inline-block;
					position: absolute;
					width: 150px;
					font-size: 16px;
					margin-top: -3px; }
				.name {
					font-size: 20px; } }

			.middle {
				width: 55%;
				.text {
					height: 30px;
					width: 30px;
					vertical-align: middle;
					border: 0;
					margin: 5px;
					padding: 7px 0;
					background-color: $mediumBlue;
					font-size: 11px; }
				.name {
					margin-top: 8px;
					font-size: 18px; } } } }

	.book {
		top: 15px;
		right: 40px;
		left: 40px;

		.priceGroup {

			.priceWrapper {
				margin-top: 20px;
				border-bottom: 1px solid #B7DBE5;

				.tag {
					margin-top: 13px;
					float: left;
					font-size: 24px; }
				.price {
					display: inline-block;
					font-size: 36px;
					float: right; }
				.hoverButton.circle {
					height: 22px;
					width: 22px;
					margin-left: 8px;
					margin-top: 19px;
					float: left;
					i {
						font-size: 14px; } } }

			.infoWrapper {
				margin-top: 10px;
				text-align: left;

				p {
					font-size: 13px;
					font-weight: 300;
					margin-bottom: 10px;
					line-height: 1.1em;
					color: #898989;
					width: 88%;
					float: left; }

				.hoverButton {
					float: right;
					margin-top: 1px; } } }

		.alignButtom {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;

			button {
				width: 100%;
				height: 75px;
				font-size: 23px; }

			h5 {
				margin: 7px 0 10px; }
			h4, {
				margin: 0;
				color: $lightBlueFont; } } } }
