/*
 |-----------------------------------------
 | Table of Contents
 |
 |
 | 1.0 Fonts
 |
 | 2.0 Element Rules
 |
 | 3.0 General Rules
 |
 | 4.0 Bootstrap Rules
 | 	4.1 Custom Bootstrap Rules
 |
 | 5.0 No Js Rules
 |
 | 6.0 Assets
 |
 | 7 Navigation
 | 	7.1 Booking Steps
 |
 | 8.0 Overview Panel
 |
 |-----------------------------------------
 */

@import "_variables";


/*
 |-----------------------------------------
 | 1.0 Fonts
 |-----------------------------------------
 */

@import url('https://fonts.googleapis.com/css?family=Mukta+Vaani:300, 400');
$defaultFont: "Mukta Vaani", sans-serif;

$defaultFont2: "Helvetica Neue",Helvetica,Arial,sans-serif;



/*
 |-----------------------------------------
 | 2.0 Element Rules
 |-----------------------------------------
 */

html,
body {
	font-family: $defaultFont;
	font-weight: 300; }

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 300; }

h1,
span.h1 {
	font-size: 30px; }

h2,
span.h2 {
	font-size: 28px; }

h3,
span.h3 {
	font-size: 26px; }

h4,
span.h4 {
	font-size: 20px; }

h5,
span.h5 {
	font-size: 16px; }

h6,
span.h6 {
	font-size: 14px; }

span.p {
	font-size: 15px; }

input[type="file"].hidden {
	position: absolute;
	height: 0.1px !important;
	width: 0.1px !important;
	display: block !important;
	overflow: hidden;
	z-index: -1;
	opacity: 0;

	&:focus  label.btn {
		background-color: #31b0d5;
		outline: 1px dotted $blue;
		outline: -webkit-focus-ring-color auto 5px; } }

label + input[type="radio"] {
	margin-left: 15px; }



/*
 |-----------------------------------------
 | 3.0 General Rules
 |-----------------------------------------
 */

.framed {
	border: 2px dashed $blue;
	padding: 20px; }

.relative {
	position: relative; }

body .scroll {
	overflow: scroll; }

table.bordered {
	tbody {
		tr {
			th,
			td {
				border: 1px solid #aaa; } } } }

table.table-large {
	tbody {
		tr {
			th,
			td {
				text-align: center;
				vertical-align: middle;
				padding: 3px;
				input {
					width: 40px; } } } } }


/*
 |-----------------------------------------
 | 4.0 Bootstrap Rules
 |-----------------------------------------
 */

.alert {
	border-radius: 0; }

.alert.alert-info,
.alert.alert-danger,
.alert.alert-warning {
	border-radius: 0;
	border-width: 0;
	border-left-width: 5px; }

.alert.alert-info {
	border-left-color: #79B7D2; }

.alert.alert-warning {
	border-left-color: #e0ad2b;
	color: #8a6d3b; }

.alert.alert-danger {
	margin-top: 10px;
	border-left-color: $red; }


/*
 |-----------------------------------------
 | 4.1 Custom Bootstrap Rules
 |-----------------------------------------
 */

.checkbox-large {
	height: 40px;
	width: 40px; }

.form-group.form-radio {
	&.inline {
		margin-right: 20px; }

	input,
	label {
		height: auto;
		width: auto;
		display: inline; }

	input {
		margin-right: 5px; } }

.margin-right-md {
	margin-right: 20px; }

.margin-bottom-lg {
	margin-bottom: 45px; }

.margin-bottom-lg:last-of-type {
	margin-bottom: 120px; }

.push-down {
	position: absolute;
	top: 100%; }

.text-blue {
	color: $darkBlue; }


/*
 |-----------------------------------------
 | 5.0 No Js Rules
 |-----------------------------------------
 */

.js-show {
	display: none !important; }



/*
 |-----------------------------------------
 | 	6.0 Assets
 |-----------------------------------------
 */

.block { // Allows the input elements to fill remaining width
	display: block; }

.horizontal-border {
	height: 0;
	left: 0;
	right: 0;
	position: absolute;
	margin: 0 auto;
	width: 50%;
	border-bottom: 1px solid #ccc; }

.form-collection { // Separates form-groups in sections.
	width: 100%;
	margin-bottom: 80px; }

.full-width {
	width: 100%; }

.no-margin {
	margin: 0 !important; }

.no-padding {
	padding: 0 !important; }

.sticky {
	position: fixed !important;
	margin-top: 0px !important;
	width: 100%; }

.fixed {
	position: fixed;
	top: 0;
	width: auto;
	width: inherit; }

.col-md-3 .fixed {
	width: 25%; }

.display-table {
	display: table;
	height: 100%;

	.row {
		display: table-row;
		height: 100%;

		.col-md-1,
		.col-md-2,
		.col-md-3,
		.col-md-4,
		.col-md-5,
		.col-md-6,
		.col-md-7,
		.col-md-8,
		.col-md-9,
		.col-md-10,
		.col-md-11,
		.col-md-12,
		.col-xs-1,
		.col-xs-2,
		.col-xs-3,
		.col-xs-4,
		.col-xs-5,
		.col-xs-6,
		.col-xs-7,
		.col-xs-8,
		.col-xs-9,
		.col-xs-10,
		.col-xs-11,
		.col-md-12 {
			display: table-cell;
			height: 100%;

			&:first-of-type {
				padding-left: 0; }

			&:last-of-type {
				padding-right: 0; }

			& > *:only-child {
				height: 100%; } } } }



/*
 |-----------------------------------------
 | 	7.0 Navigation
 |-----------------------------------------
 */

.navbar {
	min-height: auto;
	margin-bottom: 0;
	background-color: rgba(255,255,255,0.7);
	//box-shadow: 6px 5px 8px -2px rgba(0,0,0,0.3), 2px 2px 3px rgba(0,0,0,0.1);
	//box-shadow: 6px 5px 8px -2px rgba(255,255,255,0.5), 2px 2px 3px rgba(255,255,255,0.1);
	//box-shadow: 6px 5px 8px -2px rgba(0,0,0,0.5), 2px 2px 3px rgba(0,0,0,0.2);

	.container {
		width: 95%; }

	&.navbar-default {
		border-radius: 0px;
		border: none;
		margin-top: 30px;
		//margin-left: 10px;
		//margin-right: 10px;
		background-color: rgba(255,255,255,1.0);
		//background-color: rgba(0,0,0,0); /* Prevent bootstraps background-color to overwrite the gradient. */
		//background-image: -moz-linear-gradient(to left, rgba(255, 255, 255, 0.7) 35%, rgba(0, 255, 255, 0) 55%);
		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		transition: all 0.5s ease;

		&::after {
			//position: absolute;
			z-index: -1;
			content: '';
			bottom: 0;
			right: 0;
			left: auto;
			//width: 60%;
			//height: 200px;
			//box-shadow: 100px 5px 10px -3px rgba(0,0,0,0.4), 100px 3px 5px -3px rgba(0,0,0,0.5), /* inset 0 1px 2px rgba(0,0,0,0.1); */
			border-radius: 300px / 50px;
			border-bottom-right-radius: 0;
			margin-bottom: -10px; }

		.navbar-brand {
			color: $blue;
			//height: 115px
			height: auto;
			-webkit-transition: all 0.5s ease;
			-moz-transition: all 0.5s ease;
			-ms-transition: all 0.5s ease;
			transition: all 0.5s ease;

			img {
				height: 90px; } }

		ul.navbar-nav {
			font-size: 18px;
 } }			//padding-top: 65px

	.navbar-nav {

		& > .active > a,
		& > .active > a:focus {
			text-decoration: underline; }

		& > .active > a,
		& > .active > a:focus,
		& > li > a,
		& > li > a:hover {
			color: $blue;
			background-color: transparent; }

		& > li > a:hover {
			text-decoration: underline; }

		& > .open > a,
		& > .open > a:focus,
		& > .open > a:hover {
			background: none;
			color: $blue; } } }

.navbar-default .navbar-nav > .open > a,
.nav .open > a {
	background-color: transparent;
	color: $blue; }

.navbar-inverse {
	//background-color: $blue !important;
	color: #ffffff !important;
	line-height: 20px !important;
	font-size: 12px;
	font-weight: bold;
	min-height: 0px;
	padding-top: 5px !important;
	padding-bottom: 5px !important; }

.myhelpbar {
	padding-top: 5px !important;
	padding-bottom: 5px !important;
	min-height: 0px !important; }

.noheight {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
	min-height: 0px !important; }

nav.navbar.shrink {
	height: auto; }

.imgshrink {
	height: 50px !important;
	-webkit-transition: 0.5s ease;
	-moz-transition: 0.5s ease;
	-ms-transition: 0.5s ease;
	transition: 0.5s ease; }

.imgnoshrink {
	height: 90px !important;
	-webkit-transition: 0.3s ease;
	-moz-transition: 0.3s ease;
	-ms-transition: 0.3s ease;
	transition: 0.3s ease; }

/*
 |-----------------------------------------
 | 		7.1 Booking Steps
 |-----------------------------------------
 */
$stepBlue: $blue;
$stepGrey: #555;

#steps {
	display: inline-block;
	margin: 40px auto 25px;

	.step {
		display: inline-block;
		width: 240px;
		position: relative;
		padding: 0;
		margin: 0;
		text-align: center;

		.top {
			height: 3px;
			text-align: center;

			div {
				display: inline; }

			.arrow-down {
				visibility: hidden; } }

		.text {
			display: inline-block;
			border-radius: 100%;
			height: 35px;
			width: 35px;
			padding-top: 6px;
			padding-left: 1px;
			text-align: center;
			background-color: $stepBlue;
			color: #fff;
			font-size: 17px;
			position: relative; }

		.connection {
			height: 7px;
			position: absolute;
			top: 17px;
			width: 50%;
			background-color: $stepBlue; }

		.connection.first {
			left: 0; }

		.connection.second {
			right: 0; }

		.name {
			text-align: center;
			font-size: 15px; }

		&.active {
			.arrow-down {
				visibility: visible; }

			.connection.second, {
				background-color: $stepGrey;
				opacity: 0.96; }

			&.active ~ .step {
				.text,
				.connection {
					background-color: $stepGrey; } } }

		&:first-of-type .connection.first,
		&:last-of-type .connection.second {
			display: none; } } }



/*
 |-----------------------------------------
 | 	8.0 Overview Panel
 |-----------------------------------------
 */

.overview.sidePanel {
	max-width: 315px;
	margin-top: 20px;
	padding: 10px 26px 80px;
	border-left: 1px solid #ccc;

	h1 {
		font-size: 38px; }

	h3 {
		font-size: 22px;
		margin-bottom: 2px; }

	table * {
		vertical-align: bottom; }

	table {
		width: 100%;
		td {
			padding-bottom: 13px;
			font-size: 17px; } }

	p {
		text-align: justify;
		font-size: 14px;
		color: #777; }

	button.btn-default {
		width: 100%; }

	button.btn-success {
		height: 50px; }

	button.btn-success.col-xs-6:last-of-type {
		font-size: 18px;
		width: 49%;
		opacity: 0.8; }

	button.btn-success.col-xs-6:first-of-type {
		width: 49%;
		font-size: 23px; }

	.horizontal-border {
		width: 90%; } }


.btn-sq-lg {
	width: 150px !important;
	height: 150px !important; }

.btn-sq {
	width: 100px !important;
	height: 100px !important;
	font-size: 10px; }

.btn-sq-sm {
	width: 50px !important;
	height: 50px !important;
	font-size: 10px; }

.btn-sq-xs {
	width: 25px !important;
	height: 25px !important;
	padding: 2px; }

.vcenter {
	position: absolute;
	top: 33%;
	float: none; }

.dtvspacer {
	//position: relative;
	min-height: 500px; }

.mysemibg {
	background-color: rgba(255,255,255,0.7) !important;
	box-shadow: 6px 5px 8px -2px rgba(0,0,0,0.5), 2px 2px 3px rgba(0,0,0,0.2) !important; }

.panel-highlight {
	background-color: $blue !important;
	color: #ffffff !important;
	font-size: 16px !important;
	min-height: 280px; }

.panel-front {
	margin: 10px !important;
	border: none !important;
	text-align: center !important; }

.fontboost {
	font-size: 42px; }

.extramargin {
	margin-left: 5px; }

.vertical-align {
	display: flex;
	align-items: center; }

.btn-file {
	position: relative;
	overflow: hidden; }

.btn-file input[type=file] {
	position: absolute;
	top: 0;
	right: 0;
	min-width: 100%;
	min-height: 100%;
	font-size: 100px;
	text-align: right;
	filter: alpha(opacity=0);
	opacity: 0;
	outline: none;
	background: white;
	cursor: inherit;
	display: block; }
